.page-container {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 5%;
  min-height: 100vh;
  box-sizing: border-box;
}

.navbar-container {
  position: fixed; /* Keep it fixed at the top */
  top: 0; /* Align it to the top of the viewport */
  left: 55%;
  right: 0;
  top: 2.5%;
  z-index: 1000;
  transition: opacity 0.5s ease;
}

@media (max-width: 900px) {
  .navbar-container {
    left: 0; /* Align to the left edge */
    right: 0; /* Stretch to the right edge, ensuring it's always fully visible */
    top: 0; /* Stick to the top */
    display: flex; /* Ensure the navbar is displayed */
    justify-content: center; /* Center the contents */
    z-index: 1000; /* Ensure it's above other content */
  }

  .card {
    max-width: 100%; /* Make card width 100% on small screens */
  }
  .chip-container {
    flex-wrap: wrap; /* Allow chips to wrap to the next line */
    justify-content: center; /* Center-align chips when they wrap */
  }

  .page-container {
    padding-top: 60px;
  }

  .block {
    flex-direction: column; /* Stack image and text content vertically */
  }

  .about-me-text {
    text-align: center;
  }

  /* If you want to center the icons as well */
  .social-icons a {
    display: inline-block; /* Make the anchor elements behave like inline elements */
    margin: 0 5px; /* Optional: Add some horizontal space between icons */
  }
}

@media (max-width: 700px) {
  .experience-list-item {
    display: none !important;
  }

  .card-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: -20px;
    min-width: 175px;
    margin-top: 5px;
  }

  .MuiCard-root .MuiCardMedia-root {
    display: none;
  }

  .subtitle2 {
    display: none !important;
  }

  .card-content .subtitle,
  .card-content h5,
  .card-content .subtitle1 {
    font-size: 1.15rem !important; /* Adjust the font size as needed */
    padding: 10px;
  }

  .project-card {
    margin-bottom: -40px !important;
  }
}

@media (max-width: 400px) {
  .navbarText {
    font-size: 14px !important;
  }
}

.navbar-container {
  display: flex; /* Show the navbar on larger resolutions */
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 990px;
  width: 100%;
  padding: 0 20px;
  flex-grow: 1;
}

.footer {
  position: relative;
  width: 100%;
  top: 12.5vh;
  padding: 5px; /* Add some padding for aesthetics */
}

.navbar {
  padding: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  z-index: 1000;
}

.navbarText {
  margin-right: 16px;
  font-size: 18px;
  cursor: pointer;
  text-decoration: none; /* Removes underline by default */
  color: inherit; /* Inherits color from parent */
  opacity: 0.4; /* Default opacity for non-active links */
}

.navbarText:hover {
  text-decoration: underline; /* Adds underline on hover */
  opacity: 1;
}

.navbarTextActive {
  opacity: 1; /* Ensures full opacity for the active link */
  font-weight: 650;
}

.darkModeSwitch {
  opacity: 0.6;
}

.darkModeSwitch:hover {
  opacity: 1;
}

.bold-green {
  font-weight: 775;
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  background-size: 200% auto;
  transition: background-image 0.5s ease-in-out;
}

body.transition {
  transition: background-color 0.5s ease-in-out;
}

@keyframes fade-in {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.block {
  opacity: 0;
  transform: translateY(-20px); /* Start from this position before animating */
  animation: fade-in 0.6s ease forwards;
}

.block:nth-child(2) {
  animation-delay: 0.05s;
}

.block:nth-child(3) {
  animation-delay: 0.1s;
}

.block:nth-child(4) {
  animation-delay: 0.15s;
}

.block:nth-child(5) {
  animation-delay: 0.2s;
}

.social-icons {
  margin-bottom: 75px;
  margin-top: 30px;
}

.social-icons svg {
  font-size: 40px;
  margin-right: 25px;
}

.social-icons a svg {
  transition: transform 0.3s ease, filter 0.3s ease;
}

.social-icons a:hover svg {
  transform: scale(1.2);
  transition: transform 0.3s ease;
  filter: brightness(1.2);
}

.social-icons a:active svg {
  transform: scale(0.9);
  filter: brightness(0.8);
}

.timeline-container {
  display: flex;
  align-items: flex-start; /* Keep items aligned at the top */
  justify-content: flex-start; /* Align everything to the left */
  width: 100%; /* Ensure the container spans the full width */
  margin-top: 20px; /* Adjust as needed */
  max-width: 990px;
  margin-left: -200px;
}

.timeline {
  flex: 1; /* Allows the timeline to grow as needed, adjust the ratio as needed */
  padding-right: 20px; /* Spacing between the timeline and the cards */
}

.timeline-cards {
  flex: 3; /* Allows the card section to take more space than the timeline, adjust the ratio as needed */
  display: flex;
  flex-direction: column;
  gap: 20px; /* Spacing between cards */
}

/* Additional styles for MUI Cards if needed */
.card {
  padding: 20px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Basic shadow for depth, adjust as needed */
}

@media (max-width: 768px) {
  /* Adjust the max-width as needed */
  .timeline-container {
    flex-direction: column; /* Stack timeline and cards vertically */
    align-items: center; /* Center-align the items for better presentation */
    max-width: 100%; /* Allow the container to take full width on small screens */
    margin-left: 0px;
  }

  .timeline,
  .timeline-cards {
    width: 100%; /* Ensure timeline and cards take full width */
    max-width: 100%; /* Prevent overflow */
  }

  .timeline-cards {
    width: 100%; /* Ensure timeline and cards take full width */
    max-width: 100%; /* Prevent overflow */
    padding-right: 10px; /* Reduce right padding to allow more width for the cards */
  }

  /* If you want to directly adjust the card width instead */
  .card {
    margin-right: 10px; /* Increase right margin to push cards a bit to the left */
    max-width: calc(
      100% + 20px
    ); /* Optional: Increase card width slightly beyond 100% if there's room */
  }
}
